import React, {useEffect, useState} from 'react';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import EditableTextField from "../pages/editableTextField";
import { useSelector, useDispatch } from "react-redux";
import {
  getselect_options
} from "../redux/slices/leadSlice";



const Auth = localStorage.getItem("Auth");
const access_token = localStorage.getItem("Auth");
const org = localStorage.getItem("org");


const DynamicTable = (props) => {



    /////get all the unique coloumn names that can be used 

    const getUniqueKeys = () => {
      const excludedKeys = ['organization_id', 'ownerId', 'Id']; // Keys to exclude
      const keys = data.reduce((acc, obj) => {
        Object.keys(obj).forEach((key) => {
          if (!acc.includes(key) && !excludedKeys.includes(key)) {
            acc.push(key);
          }
        });
        return acc;
      }, []);
      return keys;
    };

  const [data, setData] = useState([]);  

  

  const [LeadData, setLeadData] = useState({});


  const [formFields, setFormFields] = useState([
  ]);


  const [selectoptiontype, SetSelectoptiontype ]= useState({'pipeline':'select',
  'pipelineCurrentStage':'select',
  'visibleTo':'select',
  'owner':'select',
  'priority':'select',
  'currency':'select',
  'expectedClosingDate':'date',
  'lead_status':'select',
  'Contact_Source':'select'});

  

  const [selectoptionvalues, SetSelectoptionvalues ]= useState({'currency':['INR','USD','EURO','AED'],
  'visibleTo':['Item owner', 'Only admin', 'All users'],
  'priority':['low', 'medium', 'high'],
  'lead_status':['','Won', 'Lost','Closed']
});

  const [selectoptions, setSelectoptions] = useState({});

  const uniqueKeys = getUniqueKeys();
  const dispatch = useDispatch();

  const ticketIndex = uniqueKeys.indexOf('TicketId');
  if (ticketIndex > -1) {
    uniqueKeys.splice(ticketIndex, 1);
    uniqueKeys.unshift('TicketId');
  }


  ///// to transform the addform data to key and value 
  const transformDataFormat = (dataFormatArray) => {
    const transformedObject = {};
  
    dataFormatArray.forEach((item) => {
      const { name, type } = item;
      transformedObject[name] = type;
    });
  
    return transformedObject;
  };


  function getOptionData() {
    dispatch(getselect_options({ access_token, org })).then((res) => {
      if (res.payload.status == 200) {
        setSelectoptions(res.payload.data);
        SetSelectoptionvalues({
          ...selectoptionvalues,
          ...res.payload.data,
        });

      }
    });
    
  }



  const handleUpdate = (data, value, keynamevalue) => {

    let finaldata = { ...LeadData, [keynamevalue]: value };
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios.post("/update_leads", finaldata, { headers }).then((data) => {
      if (data.status == "200") {
    

        toast.success("Lead updated successfully!", {
          position: "bottom-left",
          autoClose: 1000,
        });
        updateData(finaldata);

        // if(props.apitype == 'simple'){
        //   getLeads();
        // }else{
        //   submitAdvanceFilter();
        // }

      }
    });
  };

  const updateData = (updatedItem) => {
    setData(prevData =>
        prevData.map(item =>
            item.Id === updatedItem.Id ? updatedItem : item
        )
    );
};

  function getFormData() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/get_addformfields",
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setFormFields(data.data.data_format);
          
          SetSelectoptiontype({
            ...selectoptiontype,
            ...transformDataFormat(data.data.data_format),
          });

        }

        // setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log("error", err));
  }

  
  function getLeads() {
    const headers = {
      "Content-Type": "application/json",
       Authorization: `Bearer ${Auth}`,
    };
    axios
      .post(
        "/get_leads", props.searchobject ,
        {headers}
      )
      .then((data) => {
        if (data.status == "200") {
          setData(data.data.data);
        }
  
      })
      .catch((err) => {
        console.log(err);
      
      });
  }

  function submitAdvanceFilter () {


    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/get_leads_advance", props.searchobject, {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setData(data.data.data);
        }

      })
      .catch((err) => {
        console.log(err);
      });
  };





  useEffect(()=>{

    getOptionData();
    getFormData();

    if(props.apitype == 'simple'){
      getLeads();
    }else{
      submitAdvanceFilter();
    }

  },[])


  return (
    <table className="table table-striped table-bordered border-secondary">
      <thead>
        <tr>
          {uniqueKeys?.map((key) => (
            <th key={key}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => (
          <tr key={index}>
            {uniqueKeys?.map((key) => (
              <td key={key} onClick={()=>setLeadData(item)}>  {key=='TicketId' || key=='addedAtUTC' || key=='addedAt' ?item[key] :<EditableTextField
              data={{ [key]: item[key] }}
             handleBlur={handleUpdate}
              keyname={key}
              placeholder={key}
              type={
                selectoptiontype[key] == ''?'text':  selectoptiontype[key]
              }
              options={
                key == 'pipelineCurrentStage'? selectoptions[item.pipeline]:
                selectoptionvalues[key]
              
              }
            /> }</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DynamicTable;
